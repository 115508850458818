import videojs from 'video.js'
import { Events } from '|>/shared/events'
import { UPDATE_REFRESH_INTERVAL, bind_, throttle } from '|>/shared/fn'
import { on, register } from '|>/shared/vjs'
import { BaseSlider } from '../base'
import { LivePlayProgressBar } from './live-play-progress-bar'

import './live-seek-bar.css'

const CLICK_PROGRESS_BAR_THROTTLE = 700

const CHECK_NEW_URL_READY_INTERVAL = 700

const PROGRESS_BAR_LIVE_TOLERANCE_PIXELS = 7

/**
 * Live seekable bar
 */
@register
export class LiveSeekBar extends BaseSlider {
  declare livePlayProgressBar: LivePlayProgressBar

  @on(Events.Media.IsRewindPending)
  private isRewindPending: Events.Media.IsRewindPending = false

  @on(Events.Media.IsRewound)
  private isRewound: Events.Media.IsRewound = false

  @on(Events.Media.RewoundTargetSec)
  private rewoundTargetStartSec: Events.Media.RewoundTargetSec = 0

  @on(Events.Media.RewoundUrlSec)
  private rewoundUrlStartSec: Events.Media.RewoundUrlSec = 0

  updateInterval: number | undefined

  tempPercent: number | undefined // [0 - 1]
  start: number | undefined // stamp ms
  end: number | undefined // stamp ms

  constructor(player) {
    super(player)

    this.updateOwnPositionForced = bind_(this, this.updateOwnPosition)
    this.updateOwnPosition = throttle(
      this.updateOwnPositionForced,
      UPDATE_REFRESH_INTERVAL
    )

    this.updateForced = bind_(this, this.update)
    this.update = throttle(this.updateForced, UPDATE_REFRESH_INTERVAL)

    this.handleMouseUpForced = bind_(this, this.handleMouseUp)
    this.handleMouseUp = throttle(
      this.handleMouseUpForced,
      CLICK_PROGRESS_BAR_THROTTLE
    )

    this.handleKeyDown = bind_(this, this.handleKeyDown)
    videojs.on(document, 'keydown', this.handleKeyDown)
  }

  override createEl() {
    return super.createEl(
      'div',
      { className: 'vjs-progress-holder vjs-x-live-progress-holder' },
      { 'aria-label': this.localize('Live Progress Bar') }
    )
  }

  getLivePercent() {
    // atm we could go rewind only by user interaction -> new url
    // so any live playback network issues should not cause live edge be lost
    return 1
  }

  getRewoundPercent() {
    if (this.end === undefined || this.start === undefined) return

    const progressLengthMs = this.end - this.start
    const startMs = this.start
    const currentTime = this.player_.currentTime()

    let currentStamp = 0
    if (this.rewoundTargetStartSec === this.rewoundUrlStartSec) {
      // that "timeshift" case
      currentStamp = this.rewoundTargetStartSec + currentTime
    } else {
      // that "dvr/rewind" case
      currentStamp = this.rewoundUrlStartSec + currentTime
    }

    const currentStampMs = currentStamp * 1000

    return (currentStampMs - startMs) / progressLengthMs
  }

  getPercent() {
    if (this.tempPercent || this.isRewindPending) return this.tempPercent

    if (this.isRewound) return this.getRewoundPercent()

    return this.getLivePercent()
  }

  override handleMouseMove(event, _mouseDown = false) {
    super.handleMouseMove(event)

    if (event) {
      event.stopPropagation()
    }

    if (this.parentComponent_.isInteractive === false) {
      return
    }

    const percent = this.calculateDistance(event)
    this.tempPercent = percent
  }

  /*
   * Last progress bar click may trigger new url load and player init new sourceUrl
   * so we check isRewindPending, and keeping saved last click point in tempPercent
   */
  clearWithDelayTempPercent(trailing: boolean = false) {
    if (trailing)
      return window.setTimeout(
        bind_(this, this.clearWithDelayTempPercent),
        CHECK_NEW_URL_READY_INTERVAL
      )

    if (!this.isRewindPending) return (this.tempPercent = undefined)

    window.setTimeout(
      bind_(this, this.clearWithDelayTempPercent),
      CHECK_NEW_URL_READY_INTERVAL
    )
  }

  @on(Events.Player.GoLive)
  setTempPercentOnGoLive() {
    this.tempPercent = this.getPercent()
    // delay is to wait for isRewindPending become true
    this.clearWithDelayTempPercent(true)
  }

  handleMouseUpForced: typeof this.handleMouseUp
  override handleMouseUp(event) {
    super.handleMouseUp(event)

    if (event) {
      event.stopPropagation()
    }

    if (this.parentComponent_.isInteractive === false) {
      return
    }

    if (!this.end || !this.start) {
      return
    }

    const elWidth = this.el_.offsetWidth

    const percent = this.calculateDistance(event)

    this.tempPercent = percent
    // delay is to wait for isRewindPending become true
    this.clearWithDelayTempPercent(true)

    const rewindToStampSec = Math.round(
      (this.start + (this.end - this.start) * percent) / 1000
    )

    const percentPerPixel = 1 / elWidth // one pixel presents such percent
    const liveTolerancePercent =
      percentPerPixel * PROGRESS_BAR_LIVE_TOLERANCE_PIXELS

    if (
      this.isRewound &&
      percent + liveTolerancePercent > 1 &&
      elWidth >= PROGRESS_BAR_LIVE_TOLERANCE_PIXELS * 2
    )
      return this.player_.trigger(Events.Player.GoLive)

    console.log(
      '🚀 rewind to',
      Math.round(100 * percent) + '%',
      '->',
      new Date(rewindToStampSec * 1000)
    )

    this.player_.trigger(Events.Player.LiveRewind, rewindToStampSec)
  }

  override handleKeyDown(_event) {
    // console.log('👉', event.key)
  }

  // stepForward() {
  //   console.log('stepForward!')
  //   // this.userSeek_(this.player_.currentTime() + STEP_SECONDS);
  // }

  // stepBack() {
  //   console.log('stepBack!')
  //   // this.userSeek_(this.player_.currentTime() - STEP_SECONDS);
  // }

  updateForced: typeof this.update
  override update() {
    return super.update()
    // this.updateOwnPosition(width, boundaries)
    // const percent = this.getPercent()
    // this.bar.update(percent)
  }

  /**
   * (------[======]--------)
   * a      x      y        b
   */
  updateOwnPositionForced: typeof this.updateOwnPosition
  updateOwnPosition(
    width: number,
    [a, x, y, b]: [number, number, number, number]
  ) {
    const w = width / (b - a)
    const left = x === a ? 0 : Math.max(w * (x - a), 0)
    const right = y === b ? 0 : Math.max(w * (b - y), 0)

    const el = this.el() as HTMLElement
    el.style['margin-left'] = `${left}px`
    el.style['margin-right'] = `${right}px`

    // update start and end points
    if (x !== 0 && y !== 1) {
      this.start = x
      this.end = y
    }
  }

  @on(['playing'])
  enableInterval() {
    if (this.updateInterval) {
      return
    }

    this.updateInterval = this.setInterval(this.update, UPDATE_REFRESH_INTERVAL)
  }

  @on(['ended', 'pause', 'waiting', 'dispose'])
  disableInterval() {
    if (!this.updateInterval) {
      return
    }

    this.clearInterval(this.updateInterval)
    this.updateInterval = undefined
  }

  override dispose() {
    videojs.off(document, 'keydown', this.handleKeyDown)
    super.dispose()
  }
}

LiveSeekBar.options = {
  children: [LivePlayProgressBar.as('livePlayProgressBar')],
  barName: 'livePlayProgressBar',
}
