import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

/**
 * This is "played / colored" progress line
 */
@register
export class LivePlayProgressBar extends BaseComponent {}

LivePlayProgressBar.options = {
  className: 'vjs-play-progress vjs-slider-bar vjs-x-live-play-progress',
}
