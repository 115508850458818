import videojs, { type TPlayer } from 'video.js'
import { bind_ } from '|>/shared/fn'
import { register } from '|>/shared/vjs'
import { BaseVolumePanel } from '../base'
import { MuteToggle } from './mute-toggle'
import { VolumeControl } from './volume-control'

import './volume-bar.css'

// time to show full volume bar before hidden state
const FULL_vIEW_TIMEOUT = 1000

@register
export class VolumePanel extends BaseVolumePanel {
  declare muteToggle: MuteToggle
  declare volumeControl: VolumeControl

  fullViewTimeoutId?: number

  isMouseOverToggle: boolean
  isMouseOverSlider: boolean

  constructor(player: TPlayer, options: any) {
    super(player, options)

    this.isMouseOverToggle = false
    this.isMouseOverSlider = false

    this.checkMouseOverToggle = bind_(this, this.checkMouseOverToggle)
    this.checkMouseOverSlider = bind_(this, this.checkMouseOverSlider)
    this.checkMouseOutToggle = bind_(this, this.checkMouseOutToggle)
    this.checkMouseOutSlider = bind_(this, this.checkMouseOutSlider)
    this.toggleSliderVisibility = bind_(this, this.toggleSliderVisibility)
    this.handleArrows = bind_(this, this.handleArrows)

    this.addKeyboardHandlers()
  }

  get isMouseOver() {
    return this.isMouseOverToggle || this.isMouseOverSlider
  }

  addKeyboardHandlers() {
    videojs.on(this.muteToggle.el_, 'mouseover', this.checkMouseOverToggle)
    videojs.on(this.volumeControl.el_, 'mouseover', this.checkMouseOverSlider)
    videojs.on(this.muteToggle.el_, 'mouseout', this.checkMouseOutToggle)
    videojs.on(this.volumeControl.el_, 'mouseout', this.checkMouseOutSlider)

    // videojs.on(this.player_.el_, 'volumechange', this.toggleSliderVisibility)

    videojs.on(document, 'keydown', this.handleArrows)
    videojs.on(this.player_.el_, 'keydown', this.handleArrows)
    videojs.on(this.muteToggle.el_, 'keydown', this.handleArrows) // Component handle click stops event, thats why same for player_ and muteToggle
  }

  removeKeyboardHandlers() {
    videojs.off(this.muteToggle.el_, 'mouseover', this.checkMouseOverToggle)
    videojs.off(this.volumeControl.el_, 'mouseover', this.checkMouseOverSlider)
    videojs.off(this.muteToggle.el_, 'mouseout', this.checkMouseOutToggle)
    videojs.off(this.volumeControl.el_, 'mouseout', this.checkMouseOutSlider)

    videojs.off(this.player_.el_, 'volumechange', this.toggleSliderVisibility)

    videojs.off(document, 'keydown', this.handleArrows)
    videojs.off(this.player_.el_, 'keydown', this.handleArrows)
    videojs.off(this.muteToggle.el_, 'keydown', this.handleArrows)
  }

  checkMouseOverToggle() {
    this.isMouseOverToggle = true
  }
  checkMouseOutToggle() {
    this.isMouseOverToggle = false
  }
  checkMouseOverSlider() {
    this.isMouseOverToggle = true
  }
  checkMouseOutSlider() {
    this.isMouseOverToggle = false
  }

  handleArrows(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault()
        event.stopPropagation()
        if (this.player_.volume()) this.player_.muted(false)
        this.player_.volume(Math.min(this.player_.volume() + 0.05, 1))
        break
      }
      case 'ArrowDown': {
        event.preventDefault()
        event.stopPropagation()
        this.player_.volume(Math.max(this.player_.volume() - 0.05, 0))
        break
      }
    }
  }

  toggleSliderVisibility() {
    this.volumeControl.makeActive()
    this.fullViewTimeoutId = window.setTimeout(() => {
      if (this.isMouseOver) return
      this.volumeControl.makeInactive()
    }, FULL_vIEW_TIMEOUT)
  }

  override dispose() {
    this.removeKeyboardHandlers()
    window.clearTimeout(this.fullViewTimeoutId)

    super.dispose()
  }
}

VolumePanel.options = {
  className: 'vjs-x-volume-panel-horizontal',
  children: [MuteToggle.as('muteToggle'), VolumeControl.as('volumeControl')],
}
