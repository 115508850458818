import videojs from 'video.js'
import vi from 'video.js/dist/lang/vi.json'

videojs.addLanguage('vi', vi)
videojs.addLanguage('vi', {
  'Error': 'Lỗi',
  'Error Code': 'Mã lỗi',

  // add custom vietnamese translations here
})
