import {
  AdditionalErrorsCodes,
  type VideojsErrorsCodes,
} from '|>/shared/constants/errors'

export type VideojsMediaError = {
  code: VideojsErrorsCodes | AdditionalErrorsCodes
  message?: string
  errorCode?: string
}

export type TExternalError = {
  code: number | string
  title?: string
  message?: string
}

export enum ErrorsCodes {
  MEDIA_ERR_CUSTOM = 0,
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4,
  MEDIA_ERR_ENCRYPTED = 5,
  PLAYER_ERR_TIMEOUT = -2,
  TECH_ERROR = 10,
}
