type DrmType = 'widevine' | 'fairplay' | 'playready' // | 'primetime' | 'clearkey'

// utility type to retrieve array element type
type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

type KeySystem<T extends DrmType> = ArrayElement<(typeof keySystems)[T]>
type AllKeySystem = KeySystem<'widevine' | 'fairplay' | 'playready'>

type SupportedDrmMap = Map<DrmType, AllKeySystem[]>

/**
 * Detected supported DRM key systems
 */
export const supportedDrms: SupportedDrmMap = new Map()

/**
 * Export detected supported DRM key systems promise
 */
let resolved = (_: SupportedDrmMap): void => undefined
export const supportedDrmsResolved = //
  new Promise<SupportedDrmMap>((r) => (resolved = r))

/**
 * Media type configurations to request media key system access for
 * TODO: ideally should be even with real used streams
 */
// prettier-ignore
const supportedConfigurations = {
  widevine: [{
    initDataTypes: ['cenc'],
    videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.42E01E"', robustness: 'SW_SECURE_CRYPTO' }],
    audioCapabilities: [{ contentType: 'audio/mp4;codecs="mp4a.40.2"', robustness: 'SW_SECURE_CRYPTO' }],
  }],
  fairplay: [{
    initDataTypes: ['cenc'],
    videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.42E01E"' }],
    audioCapabilities: [{ contentType: 'audio/mp4;codecs="mp4a.40.2"' }],
  }],
  playready: [{
    initDataTypes: ['cenc'],
    videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.42E01E"' }],
    audioCapabilities: [{ contentType: 'audio/mp4;codecs="mp4a.40.2"' }],
  }]
} satisfies Record<DrmType, MediaKeySystemConfiguration[]>

/**
 * Known key systems to request
 */
// prettier-ignore
const keySystems = {
  widevine: [
    'com.widevine.alpha',
  ],
  fairplay: [
    'com.apple.fairplay',
    'com.apple.fps',
    'com.apple.fps.1_0',
    'com.apple.fps.2_0',
    'com.apple.fps.3_0',
  ],
  playready: [
    'com.microsoft.playready',
    'com.youtube.playready',
    'com.microsoft.playready.recommendation',
    'com.microsoft.playready.hardware',
  ],
  // primetime: [ // we do not support primetime
  //   'com.adobe.primetime',
  //   'com.adobe.access',
  // ],
  // clearkey: [ // we do not support clearkey
  //   'org.w3.clearkey',
  //   'webkit-org.w3.clearkey',
  // ],
} as const satisfies Record<DrmType, string[]>

// request navigator about supported DRM systems
// maybe redo to implicit getter?
;(async () => {
  const emeSupported =
    'MediaKeys' in window ||
    'WebKitMediaKeys' in window ||
    'MSMediaKeys' in window

  const requestMediaKeySystemAccessSupported =
    'requestMediaKeySystemAccess' in window.navigator

  if (!emeSupported || !requestMediaKeySystemAccessSupported) {
    return
  }

  const requests: Promise<{ type: DrmType; key: AllKeySystem } | null>[] = []
  for (const [type, keys] of Object.entries(keySystems)) {
    const tries = keys.map(
      (key) =>
        navigator
          .requestMediaKeySystemAccess(key, supportedConfigurations[type])
          .then(() => ({ type, key }) as { type: DrmType; key: AllKeySystem })
          .catch(() => null) // ignore and suppress error
    )
    requests.push(...tries)
  }

  const results = await Promise.all(requests)

  for (const result of results) {
    if (result != null) {
      const keys = supportedDrms.get(result.type) || []
      keys.push(result.key)
      supportedDrms.set(result.type, keys)
    }
  }

  resolved(supportedDrms)
})()
