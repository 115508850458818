import type { TPlayer } from 'video.js'
import { addClass, insertContent, span } from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseMenuItem, BaseSubsCapsButton } from '../base'

/**
 * Subtitles menu item in settings menu
 * under the hood uses native SubsCapsButton to change text track
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/text-track-controls/subs-caps-button.js
 */
@register
export class SettingsMenuSubsMenu extends BaseMenuItem {
  declare selectedEl_: HTMLSpanElement

  track_: any // InstanceType<typeof BaseSubsCapsButton>
  submenu: any // InstanceType<typeof BaseSubsCapsButton>['menu']

  constructor(player: TPlayer, options: any) {
    super(player, { ...options, label: player.localize('Subtitles') })

    this.track_ = new BaseSubsCapsButton(player, {
      title: player.localize('Settings'),
    })

    const changeHandler = () => this.updateSelected()

    const tracks = this.player_.textTracks()
    player.on(['loadstart', 'texttrackchange'], changeHandler)
    tracks.addEventListener('change', changeHandler)
    tracks.addEventListener('selectedlanguagechange', changeHandler)
    this.on('dispose', () => {
      player.off(['loadstart', 'texttrackchange'], changeHandler)
      tracks.removeEventListener('change', changeHandler)
      tracks.removeEventListener('selectedlanguagechange', changeHandler)
    })
  }

  override createEl(type?: string, props?: any, attrs?: any) {
    const el = super.createEl(type, props, attrs)
    addClass(el, 'vjs-x-settings-menu-subs-menu')
    el.prepend(span('.vjs-icon-placeholder', { ariaHidden: 'true' }))
    el.append((this.selectedEl_ = span('.vjs-x-selected')))
    return el
  }

  override handleLanguagechange() {
    // update title
    const elTitle_ = this.$('.vjs-menu-item-text') as HTMLElement
    if (elTitle_) {
      insertContent(elTitle_, this.localize('Subtitles'))
    }

    // update settings submenu title
    const settingsTitleEl_ = this.$('.vjs-menu-title') as HTMLElement
    if (settingsTitleEl_) {
      insertContent(settingsTitleEl_, this.localize('Settings'))
    }
  }

  updateSelected() {
    let selected
    const tracks = this.player_.textTracks()
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]
      if (track.mode === 'showing') {
        selected = track
        break
      }
    }
    const label = selected
      ? selected.label || selected.language || 'Unknown'
      : 'Off'
    insertContent(this.selectedEl_, label)
  }

  update() {
    this.updateSelected()

    this.track_.update()
    this.submenu = this.track_.menu
    this.submenu.addClass('vjs-x-settings-menu', 'vjs-x-settings-submenu')
    this.submenu.hide()

    // ad hoc workaround to hide submenu on any menu child item tap
    // TODO: find a better solution
    this.submenu.children().forEach((child) => {
      child.on('touchend', () => {
        window.setTimeout(() => this.submenu?.hide(), 0)
      })
    })

    this.addChild(this.submenu)

    // force update titles to respect locale
    this.handleLanguagechange()
  }

  override handleClick() {
    this.submenu.show()
  }
}
