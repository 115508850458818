import videojs from 'video.js'
import en from 'video.js/dist/lang/en.json'

videojs.addLanguage('en', en)
videojs.addLanguage('en', {
  'Error': 'Error',
  'Error Code': 'Error Code',

  // TODO this errors should be moved out of pureya later
  'PE005_title': 'Playback Error', // key playback_error
  'PE005_message':
    'Your browser does not currently recognize any of the video formats available. Try again later, or select a different item.', // key browser_recognize_error_message

  'PE003_title': 'Playback Error', // key playback_error
  'PE003_message':
    'Something went wrong. Please try to refresh the page or select a different item.', // key refresh_or_select_error

  'PE004_title': 'Playback Error', // key playback_error
  'PE004_message':
    'An error occurred while loading this content. Try refreshing the page or coming back a little later.', // key loading_content_error_text

  'NE001_title': 'Network Error', // key network_error_title
  'NE001_message':
    'An error occurred while loading this content. Please check your network connection and try again.', // key network_error_message

  'PE006_title': 'Playback Error', // key playback_error
  'PE006_message':
    'Cannot play video. Try again later, or select a different item.', // key cannot_play_video_error

  'DR001_title': 'Content Access Error', // key drm_error_title
  'DR001_message':
    'There is a problem playing protected (DRM) content on your device.', // key drm_error_text

  'PE010_title': 'Playback Error', // key playback_error
  'PE010_message':
    'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.', // key stream_or_network_issues_error

  'PE008_title': 'Playback Error', // key playback_error
  'PE008_message':
    'We are having issues playing this video. Please try to refresh the page or select a different item.', // key playing_media_error

  'GE001_title': 'Geoblock Error', // key geoblock_error_title
  'GE001_message': 'Sorry, {{title}} is not available in your region', // key content_geoblock

  'SE500_title': 'Server Error', // key server_error
  'SE500_message':
    'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.', // key stream_or_network_issues_error

  'AE402_title': 'Content Access Error', // key drm_error_title
  'AE402_message': 'Payment required.', // key payment_required_error

  'SE404_title': 'Server Error', // key server_error
  'SE404_message':
    'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.', // key stream_or_network_issues_error

  'AE403_title': 'Content Access Error', // key drm_error_title
  'AE403_message': 'Sorry, {{title}} is not available.', // key sorry_item_not_available
})
