import type { ICombinedPlayerTvData } from '$/player/tv/index.h'
import type { CurrentTvMediaUrlWithEpg } from '@/interfaces'
import {
  type EpgInfoDto,
  ScheduleType,
  type TimeRange,
  type TvEpgApiDto,
} from '@setplex/tria-api'
import { endOfHour, formatISO, getUnixTime, parseISO, subHours } from 'date-fns'

const MAX_REWIND_BACK_HOURS = 2

export const generateEmptyProgramForNow = (): EpgInfoDto => ({
  start: formatISO(subHours(new Date(), MAX_REWIND_BACK_HOURS)).toString(), // it replaced later with 'rewindLengthSec'
  stop: formatISO(endOfHour(new Date())).toString(),
  title: '',
  description: '',
  subtitle: '',
  categories: [],
  icon: '',
  date: '',
  rating: '',
  directors: [],
  actors: [],
  episode: '',
})

export const getCurrentProgram = ({
  epg,
}: {
  epg: TvEpgApiDto
  period: TimeRange
}): EpgInfoDto => {
  const epgId = Object.keys(epg)[0] // should be only one channel / epgId
  const programs = epg[epgId]

  if (programs.length === 0) return generateEmptyProgramForNow()
  if (programs.length === 1) return programs[0]

  // take the latest program // due to fact period is MIN_PROGRAM_LENGTH_MS minutes
  const sorted = programs.sort(
    (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
  )
  return sorted[0]
}

export const mergeUrlDataAndProgram = ({
  mediaUrlData,
  program,
  channel,
}: ICombinedPlayerTvData): CurrentTvMediaUrlWithEpg => {
  const rewindLengthSec = mediaUrlData.liveRewindOptions?.rewindLengthSec

  const hasEpg = Boolean(program.title)

  const rewindLengthH = rewindLengthSec
    ? rewindLengthSec / 3600
    : MAX_REWIND_BACK_HOURS

  const fakeStartTime = formatISO(
    subHours(new Date(), rewindLengthH)
  ).toString()
  const fakeEndTime = formatISO(endOfHour(new Date())).toString()

  const startTime = getUnixTime(
    parseISO(hasEpg ? program.start : fakeStartTime)
  )
  const endTime = getUnixTime(parseISO(hasEpg ? program.stop : fakeEndTime))

  return {
    ...mediaUrlData,
    programTitle: program.title,
    scheduleType: hasEpg ? ScheduleType.EPG : ScheduleType.HOURLY,
    startTime,
    endTime,
    channelId: channel.id,
  }
}
