import videojs from 'video.js'
import fr from 'video.js/dist/lang/fr.json'

videojs.addLanguage('fr', fr)
videojs.addLanguage('fr', {
  'Error': 'Erreur',
  'Error Code': "Code d'erreur",

  // add custom french translations here
})
