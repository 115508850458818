import type { TPlayer } from 'video.js'
import { register } from '|>/shared/vjs'
import { BaseButton } from '../base'

/**
 * Button to open settings menu
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/button.js
 */
@register
export class SettingsButton extends BaseButton {
  declare controlText_: string | undefined

  isPressed: boolean | undefined

  constructor(player: TPlayer, options: any) {
    super(player, options)
    this.isPressed = false
  }

  override handleClick() {
    if (this.isPressed) {
      this.parentComponent_.menu.hide()
    } else {
      this.parentComponent_.menu.update()
      this.parentComponent_.menu.show()
    }
    this.isPressed = !this.isPressed
  }
}

SettingsButton.prototype.controlText_ = 'Settings'
SettingsButton.options = {
  className: 'vjs-x-settings-button',
}
