import type { TPlayer } from 'video.js'
import { Events } from '|>/shared/events'
import { div, insertContent } from '|>/shared/h'
import { on, register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './title.css'

/**
 * Currently playing video title and subtitle
 */
@register
export class Title extends BaseComponent {
  declare title: Element
  declare subtitle: Element

  constructor(player: TPlayer, options?: Events.Media.TitleChanged) {
    super(player)

    // update title and subtitle on initialization from options, if any
    this.updateTitle(options)
  }

  override createEl(): Element {
    return div([
      (this.title = div('.vjs-x-title')),
      (this.subtitle = div('.vjs-x-sub-title')),
    ])
  }

  // update title and subtitle on title change event
  @on(Events.Media.TitleChanged)
  updateTitle({ title, subtitle }: Events.Media.TitleChanged = {}) {
    insertContent(this.title, title || '')
    insertContent(this.subtitle, subtitle || '')
  }
}

Title.options = {
  className: 'vjs-x-title-block',
}
