import { register } from '|>/shared/vjs'
import { BaseMouseTimeDisplay } from '../base'
import { LiveTimeTooltip } from './live-time-tooltip'

/**
 * Track mouse movement over the progress bar
 */
@register
export class LiveMouseTimeDisplay extends BaseMouseTimeDisplay {
  declare tooltip: LiveTimeTooltip

  override update(
    rect: { left: number; top: number; width: number; height: number },
    point: number,
    start?: number,
    end?: number
  ) {
    if (start !== undefined && end !== undefined) {
      const time = point * (end - start) + start
      this.tooltip.updateTime(rect, point, time, () => {
        this.el_.style.left = `${rect.width * point}px`
      })
    } else {
      // call the base class update method if `start` and `end` are not provided
      super.update(rect, point)
    }
  }
}

LiveMouseTimeDisplay.options = {
  children: [
    // display a time above the progress bar
    LiveTimeTooltip.as('tooltip'),
  ],
}
