export enum VideojsErrorsCodes {
  MEDIA_ERR_CUSTOM = 0,
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4,
  MEDIA_ERR_ENCRYPTED = 5,
  PLAYER_ERR_TIMEOUT = -2,
}

export enum AdditionalErrorsCodes {
  TECH_ERROR = 10,
}
