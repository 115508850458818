import type { TPlayer } from 'video.js'
import { addClass, insertContent, span, textContent } from '|>/shared/h'
import type { AudioTrack } from '|>/shared/types/tracks'
import { register } from '|>/shared/vjs'
import { BaseAudioTrackButton, BaseMenuItem } from '../base'

/**
 * Audio menu item in settings menu
 * under the hood uses native AudioTrackButton to change audio track
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/audio-track-controls/audio-track-button.js
 */
@register
export class SettingsMenuAudioMenu extends BaseMenuItem {
  declare selectedEl_: HTMLElement

  track_: InstanceType<typeof BaseAudioTrackButton>
  submenu: InstanceType<typeof BaseAudioTrackButton>['menu']

  constructor(player: TPlayer, options: any) {
    super(player, { ...options, label: player.localize('Audio') })

    this.track_ = new BaseAudioTrackButton(player, {
      title: player.localize('Settings'),
    })

    const changeHandler = () => this.updateSelected()

    const tracks = this.player_.audioTracks()
    tracks.addEventListener('change', changeHandler)
    this.on('dispose', () => {
      tracks.removeEventListener('change', changeHandler)
    })
  }

  override createEl(type?: string, props?: any, attrs?: any) {
    const el = super.createEl(type, props, attrs)
    addClass(el, 'vjs-x-settings-menu-audio-menu')
    el.prepend(span('.vjs-icon-placeholder', { ariaHidden: 'true' }))
    el.append((this.selectedEl_ = span('.vjs-x-selected')))
    return el
  }

  override handleLanguagechange() {
    // update title
    const elTitle_ = this.$('.vjs-menu-item-text') as HTMLElement
    if (elTitle_) {
      textContent(elTitle_, this.localize('Audio'))
      this.controlText(this.localize('Audio'))
    }

    // update settings submenu title
    const settingsTitleEl_ = this.$('.vjs-menu-title') as HTMLElement
    if (settingsTitleEl_) {
      insertContent(settingsTitleEl_, this.localize('Settings'))
    }
  }

  updateSelected() {
    let selected: AudioTrack | undefined
    const tracks = this.player_.audioTracks()
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]
      if (track.enabled) {
        selected = track
        break
      }
    }
    const label = selected?.label || selected?.language || 'Unknown'
    insertContent(this.selectedEl_, label)
  }

  update() {
    this.updateSelected()

    this.track_.update()

    this.submenu = this.track_.menu! // 'menu' exists after update
    this.submenu.addClass('vjs-x-settings-menu', 'vjs-x-settings-submenu')
    this.submenu.hide()

    // ad hoc workaround to hide submenu on any menu child item tap
    // TODO: find a better solution
    this.submenu.children().forEach((child) => {
      child.on('touchend', () => {
        window.setTimeout(() => this.submenu?.hide(), 0)
      })
    })

    this.addChild(this.submenu)

    // force update titles to respect locale
    this.handleLanguagechange()
  }

  override handleClick() {
    this.submenu?.show()
  }
}
