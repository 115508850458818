import {
  BaseComponent,
  BaseFullscreenToggle,
  BasePictureInPictureToggle,
  BaseSpacer,
} from '../base'
import { CloseButton } from '../close-button'
import { ControlBar } from '../control-bar'
import { PlayCentralToggle } from '../play-central-toggle'
import { PlayNext } from '../play-next'
import { PlayPrevious } from '../play-previous'
import { LiveProgressControl, VODProgressControl } from '../progress-control'
import { SeekToLive } from '../seek-to-live'
import { SettingsControl } from '../settings'
import { TapToUnmute } from '../tap-to-unmute'
import { Title } from '../title'
import { VolumePanel } from '../volume-panel'
import { Player } from './player'

import './default-player.css'

/**
 * Default player configuration, used by Pureya
 */
export const defaultPlayer = Player.as('.vjs-x-player', [
  // Top bunk
  BaseComponent.as('.vjs-x-top-bunk', [
    // currently playing video title
    Title,

    // space between title and close button
    BaseSpacer,

    // chromecast button
    // https://github.com/silvermine/videojs-chromecast/blob/ee87f9bcad423b48560cbb5340b5b577d61af853/src/js/components/ChromecastButton.js
    'chromecastButton',

    // close button
    CloseButton,
  ]),

  // Middle bunk
  BaseComponent.as('.vjs-x-middle-bunk', [
    // central play button toggle
    PlayCentralToggle,
  ]),

  // Bottom bunk
  BaseComponent.as('.vjs-x-bottom-bunk', [
    // VOD progress bar control
    VODProgressControl,

    // Live progress bar control
    LiveProgressControl,

    // control bar with play/pause, volume, live indicator(button) and so on controls
    ControlBar.as('.vjs-x-control-bar', [
      // displays the current time
      // BaseCurrentTimeDisplay.as(),

      // separator between the current time and duration
      // BaseTimeDivider.as(),

      // displays the duration
      // BaseDurationDisplay.as(),

      // MuteToggle and VolumeControl combined
      VolumePanel,

      // "Tap to unmute button" for touch devices combined
      TapToUnmute,

      // status of live edge and button to seek to live edge
      SeekToLive,

      // Play next content button
      PlayPrevious,

      // central play toggle button
      PlayCentralToggle,

      // Play previous content button
      PlayNext,

      // space to move latter controls to the right side
      BaseSpacer,

      // settings button, to change audio and subtitles
      SettingsControl,

      // toggle Picture-in-Picture mode
      BasePictureInPictureToggle.as(),

      // toggle fullscreen video
      BaseFullscreenToggle.as(),
    ]),
  ]),
])
