import { generateEmptyProgramForNow, getCurrentProgram } from '$/player/tv/lib'
import {
  api,
  type EpgInfoDto,
  MIN_PROGRAM_LENGTH_MS,
  type TimeRange,
  type TvChannel,
  type TvEpgApiDto,
} from '@setplex/tria-api'
import type { ApiEffect } from '@setplex/tria-api/src/index.h'
import {
  attach,
  combine,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { ssePurchasesModel } from '~/features/payment/sse-purchases'
import { type PlayerAllTvQueries } from '~/shared/interfaces'

export const initPlayerPageTvPlaying = createEvent<PlayerAllTvQueries>()

sample({
  clock: initPlayerPageTvPlaying,
  fn: ({ categoryId, ...initPlayerPageTvParams }) => ({
    ...initPlayerPageTvParams,
    categoryId: categoryId || 0,
  }),
  target: api.tv.generateTvPlaylist,
})

export const setCurrentChannel = createEvent<TvChannel>()

// update epg data (f.e. when previous is not actual anymore)
export const updatePlayingChannelEPGInfo = createEvent()
export const $currentChannel = restore(setCurrentChannel, null)

export const $currentChannelSSE = combine(
  $currentChannel,
  ssePurchasesModel.$channel,
  (channel, adjust) => (channel ? adjust(channel) : channel)
)

export const getUrlAndEpgWithRewind = createEvent<{ period: TimeRange }>()

// gets epg and live url after
export const getEpgAndLiveUrl = createEvent()

// gets 1.rewound url (epg not requested coz no rewind deeper than cur.program)
export const getRewoundUrl = createEvent<{
  rewindType?: string
  urlStartTime?: number
  targetStartTime?: number
}>()

export const getEpgInfoForTvChannelFx: ApiEffect<
  { period: TimeRange },
  { epg: TvEpgApiDto; period: TimeRange }
> = attach({
  effect: api.tv.getEpgInformationFx,
  source: { channel: $currentChannelSSE },
  mapParams: ({ period }, { channel }) => ({
    period,
    epgIds: [channel?.epgId || ''],
  }),
})

sample({
  clock: getEpgAndLiveUrl,
  fn: () => {
    const now = Date.now()
    return {
      period: {
        // that period is MIN_PROGRAM_LENGTH_MS - lower period could receive [] - empty epg due to gaps
        // but in this case also could be received two programs
        start: now - MIN_PROGRAM_LENGTH_MS,
        end: now,
      },
    }
  },
  target: getEpgInfoForTvChannelFx,
})

export const updateEpgInfoForTvChannelFx: ApiEffect<
  { period: TimeRange },
  { epg: TvEpgApiDto; period: TimeRange }
> = attach({
  effect: api.tv.getEpgInformationFx,
  source: { channel: $currentChannelSSE },
  mapParams: ({ period }, { channel }) => ({
    period,
    epgIds: [channel?.epgId || ''],
  }),
})

// real program
export const $program = createStore<EpgInfoDto | null>(null)
  .on(getEpgInfoForTvChannelFx.doneData, (_, payload) =>
    getCurrentProgram(payload)
  )
  .on(getEpgInfoForTvChannelFx.fail, () => generateEmptyProgramForNow())
  .on(updateEpgInfoForTvChannelFx.doneData, (_, payload) =>
    getCurrentProgram(payload)
  )
  .on(updateEpgInfoForTvChannelFx.fail, () => generateEmptyProgramForNow())
  .reset($currentChannelSSE)

sample({
  clock: updatePlayingChannelEPGInfo,
  source: $currentChannelSSE,
  filter: (currentChannel) =>
    currentChannel != null && currentChannel.epgId != null,
  fn: () => {
    const start = Date.now()
    return {
      period: {
        // MIN_PROGRAM_LENGTH_MS minutes period // to be sure to get valid program
        start,
        end: start + MIN_PROGRAM_LENGTH_MS,
      },
    }
  },
  target: updateEpgInfoForTvChannelFx,
})
