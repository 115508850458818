import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { LiveMouseTimeDisplay } from './live-mouse-time-display'

/**
 * Live full (could be partly disabled) progress bar
 */
@register
export class LiveFullBar extends BaseComponent {
  declare mouseTimeDisplay: LiveMouseTimeDisplay
}

LiveFullBar.options = {
  className: 'vjs-x-live-full-progress-bar',
  children: [
    // track mouse movement over the progress bar
    LiveMouseTimeDisplay.as('mouseTimeDisplay'),
  ],
}
