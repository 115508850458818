import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseButton } from '../base'

import './play-next.css'

/**
 * Play next button component
 */
@register
export class PlayNext extends BaseButton {
  @on(Events.Controls.ShowPlayNextControl)
  changeVisibility(visible: Events.Controls.ShowPlayNextControl) {
    if (visible) this.show()
    else this.hide()
  }

  override handleClick() {
    this.player_.trigger(Events.Player.PlayNext)
  }
}

PlayNext.options = {
  className: 'vjs-x-next-button',
}
