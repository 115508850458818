import { createEvent, createStore, sample } from 'effector'
import { model as popup } from '~/entities/info-popup'
import { model as subscriber } from '~/entities/subscriber'
import { installAnnouncementsModel } from '~/features/announcements'
import { model as favoritesFeature } from '~/features/favorites'
import { model as logoFeature } from '~/features/logo'
import { installNotificationsModel } from '~/features/notifications/install'
import { featuresNpawModel } from '~/features/npaw'
import { model as pingerFeature } from '~/features/pinger'
import { playbackUrlAdParamsFeatureModel } from '~/features/playback-url-ad-params'
import { siginInToWatchFeatureModel } from '~/features/signin-to-watch'
import { subscriberFeatureModel } from '~/features/subscriber'
import { model as clock } from '~/shared/clock'
import { model as telemetry } from '~/shared/opentelemetry'
import { model as sentry } from '~/shared/sentry'
import { languagePickerModel } from '~/shared/ui/components/language-picker'
import { profilesCreateWidgetModel } from '~/widgets/profiles/create'

export const init = createEvent()

// store for a flag, indicating, that launch process has been finished
// changed and monitored by other processes, like `guest` process
export const $ready = createStore<boolean>(false)

//
// application launch
//

// init entities
sample({
  clock: init,
  target: [subscriber.init, popup.init],
})

// init features
sample({
  clock: init,
  target: [
    pingerFeature.init,
    favoritesFeature.init,
    logoFeature.init,
    installNotificationsModel.init,
    playbackUrlAdParamsFeatureModel.init,
    subscriberFeatureModel.init,
    installAnnouncementsModel.init,
    siginInToWatchFeatureModel.init,
    featuresNpawModel.init,
  ],
})

// init widgets
sample({
  clock: init,
  target: [profilesCreateWidgetModel.init],
})

// setup application clock
sample({
  clock: init,
  target: clock.setup,
})

// init sentry
sample({
  clock: init,
  target: sentry.init,
})

// init telemetry
sample({
  clock: init,
  target: telemetry.init,
})

sample({
  clock: init,
  target: languagePickerModel.init,
})
