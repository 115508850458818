/**
 * This is a copy from MediaError class in video.js,
 * which in turn is an extended variant of the native MediaError interface
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaError
 */
export const MediaErrorCode = {
  MEDIA_ERR_CUSTOM: 0, // W3C error code for any custom error.
  MEDIA_ERR_ABORTED: 1,
  MEDIA_ERR_NETWORK: 2,
  MEDIA_ERR_DECODE: 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED: 4,
  MEDIA_ERR_ENCRYPTED: 5,
  PLAYER_ERR_TIMEOUT: -2,
}

export const FALLBACK_ERROR_CODE = MediaErrorCode.MEDIA_ERR_CUSTOM

/**
 * This is a mapping of the MediaErrorCode values to their names
 */
export const MediaErrorCodeName = Object.entries(MediaErrorCode) //
  .reduce((acc, [key, value]) => ((acc[value] = key), acc), {})
