import { Events } from '|>/shared/events'
import { on, register } from '|>/shared/vjs'
import { BaseProgressControl } from '../base'

import './progress-control.css' // TODO it should be split by type 'vod / live' like their classes do

/**
 * VOD progress bar control
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/progress-control/progress-control.js
 */
@register
export class VODProgressControl extends BaseProgressControl {
  @on('ended')
  checkProgramProgress() {
    this.player_.trigger(Events.Player.ProgressReachedEnd)
  }
}

VODProgressControl.options = {
  className: 'vjs-x-vod-progress-control',
}
