import { nanoid } from 'nanoid'
import 'reflect-metadata/lite'
import videojs, { type TComponent, type TPlayer } from 'video.js'

/**
 * Helper function to get VideoJS component by name
 * (or given a class will return the same class)
 */
export function Component<T = TComponent>(name: any = ''): T {
  if (typeof name === 'string') {
    const [componentName, ...classNames] = name.split('.')
    const Cls = videojs.getComponent(componentName || 'Component') as any // TComponent
    return classNames.length === 0
      ? Cls
      : class extends Cls {
          constructor(player: TPlayer, options?: any) {
            super(player, options)
            this.addClass(...classNames)
          }
        }
  }
  if (typeof name === 'function') {
    return name
  }
  throw new Error('Invalid component', name)
}

/**
 * Helper function to add new properties and static methods to the component class
 * Does some magic to be able to use the class in children list
 */
export function extend<T extends Function>(
  constructor: T,
  id: string,
  named: string = constructor.name
): T {
  // if 'as' method already exists, do nothing,
  // because target class was already enhanced
  if (Object.prototype.hasOwnProperty.call(constructor, 'as')) {
    return constructor
  }

  // set `componentClass` property to the constructor
  // so VideoJS can use it to find registered component class
  // https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/component.js#L619
  Object.defineProperty(constructor, 'componentClass', {
    value: id,
  })

  // set `name` property of the constructor
  // there is a magic hack here, in order to disable changing the name
  // https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/component.js#L622
  Object.defineProperty(constructor, 'name', {
    get: () => named,
    set: () => undefined, // do nothing
  })

  // add method `as`, so component can be aliased in-place
  // also allows to pass options as second argument (or as a single first argument)
  // there is a magic hack here, in order to disable changing the name
  // https://github.com/videojs/video.js/blob/d2b9d5c974036e637df133f38a95649ab2230490/src/js/component.js#L622
  Object.defineProperty(constructor, 'as', {
    // possible signatures:
    // - as() - no arguments at all, return object with default `name` and `componentClass`
    // - as(name: string, options?: Record<string, any>) - name and optional options
    // - as(name: string, children: any[]) - name and children
    // - as(children: any[]) - children only with default `name`
    // - as(options: Record<string, any>) - options only with default `name` (name can be set in options)
    value: (a: any, b: any) => {
      const asObject = { componentClass: id }

      let name: string
      let className: string | undefined
      let optionsOrChildren: Record<string, any> | any[] | undefined

      if (typeof a === 'string') {
        const [names, ...classes] = a.split('.')
        name = names || named
        className = classes.length > 0 ? classes.join(' ') : undefined
        optionsOrChildren = b
      } else {
        name = a?.name ?? named
        optionsOrChildren = a
      }

      if (className != null) {
        Object.assign(asObject, { className })
      }

      if (optionsOrChildren != null) {
        if (Array.isArray(optionsOrChildren)) {
          Object.assign(asObject, { children: optionsOrChildren })
        } else {
          Object.assign(asObject, optionsOrChildren)
        }
      }

      return Object.defineProperty(asObject, 'name', {
        get: () => name,
        set: () => undefined, // do nothing
      })
    },
  })

  // set `options` property to the constructor.prototype
  // so VideoJS can use it to get default options
  // this is workaround for the fact that VideoJS defines `options_` property as private
  Object.defineProperty(constructor, 'options', {
    get: () => constructor.prototype.options_,
    set: (options) =>
      (constructor.prototype.options_ = Object.assign(
        {},
        constructor.prototype.options_, // preserve existing options from base class
        options
      )),
  })

  // return modified constructor, enhanced with extended properties and methods
  return constructor
}

/**
 * Symbol key for storing listeners metadata
 */
const LISTENERS_METADATA_KEY = Symbol()
type ListenersMetadata = {
  key: string | symbol
  type: 'property' | 'method'
  event: string | string[]
  fn?: (...args: any[]) => void
}[]
type ListenersHandlersMetadata = {
  event: string | string[]
  handler: (...args: any[]) => void
}[]

/**
 * Helper decorator for registering VideoJS components
 * Does some magic to be able to use the class in children list
 */
type AnyCls = { new (...args: any[]): any }
export function register(name: string): <T extends AnyCls>(constructor: T) => T
export function register<T extends AnyCls>(constructor: T): T
export function register(constructorOrName: AnyCls | string) {
  function decorator(constructor: AnyCls) {
    // generate unique id for the component class
    // component class will be added to videojs registry with this id
    const componentClassId = nanoid()

    // get component name, given as decorator argument or from constructor
    const name =
      typeof constructorOrName === 'string'
        ? constructorOrName
        : `___component_${constructor.name}___`

    // create a new class that handles event listeners
    const Component = class extends constructor {
      constructor(player: any, ...args: any[]) {
        super(player, ...args)

        // get listeners array from the constructor metadata
        const listeners: ListenersMetadata =
          Reflect.getMetadata(LISTENERS_METADATA_KEY, constructor) || []

        // create handlers array
        const handlers: ListenersHandlersMetadata = []
        for (const { key, type, event, fn } of listeners) {
          handlers.push({
            event,
            handler: (e: any, v: any) => {
              const prop: any = Reflect.get(this, key)
              const value = fn ? fn(v, e) : v
              if (type === 'property') {
                Reflect.set(this, key, value)
              } else if (typeof prop === 'function') {
                prop.call(this, v, e)
              }
            },
          })
        }

        // attach event listeners dynamically
        for (const { event, handler } of handlers) {
          this.on(player, event, handler)
        }

        // remove event listeners on dispose
        this.on('dispose', () => {
          for (const { event, handler } of handlers) {
            this.off(this.player_, event, handler)
          }
        })
      }
    }

    // extend the constructor with new properties and methods
    extend(Component, componentClassId, name)

    // add component class to videojs registry
    videojs.registerComponent(componentClassId, Component as TComponent)

    // return new component class
    return Component
  }

  return typeof constructorOrName === 'string'
    ? decorator
    : decorator(constructorOrName)
}

/**
 * Helper decorator to add to VideoJS events listeners
 */
export function on(
  event: string | string[],
  fn?: (...args: any[]) => void
): PropertyDecorator
export function on(
  event: string | string[],
  fn?: (...args: any[]) => void
): MethodDecorator
export function on(event: string | string[], fn?: (...args: any[]) => void) {
  return function (
    target: any,
    propertyKey: string | symbol,
    descriptor?: PropertyDescriptor
  ) {
    // get listeners array from the constructor metadata
    const listeners: ListenersMetadata =
      Reflect.getMetadata(LISTENERS_METADATA_KEY, target.constructor) || []

    // add listener to the listeners array
    listeners.push({
      key: propertyKey,
      type: descriptor ? 'method' : 'property',
      event,
      fn,
    })

    // store listeners array in the constructor metadata
    Reflect.defineMetadata(
      LISTENERS_METADATA_KEY,
      listeners,
      target.constructor
    )

    return descriptor
  }
}
