import videojs from 'video.js'
import th from 'video.js/dist/lang/th.json'

videojs.addLanguage('th', th)
videojs.addLanguage('th', {
  'Error': 'ข้อผิดพลาด',
  'Error Code': 'รหัสข้อผิดพลาด',

  // add custom thai translations here
})
