import type { TPlayer } from 'video.js'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { SettingsButton } from './settings-button'
import { SettingsMenu } from './settings-menu'

import './settings.css'

/**
 * Settings button, to change audio and subtitles
 */
@register
export class SettingsControl extends BaseComponent {
  declare button: SettingsButton
  declare menu: SettingsMenu

  constructor(player: TPlayer, options: any) {
    super(player, options)
    this.menu.hide()
  }
}

SettingsControl.options = {
  className: 'vjs-x-settings-control vjs-control',
  children: [
    // button to open settings menu
    SettingsButton.as('button'),

    // settings menu with audio and subtitles options
    SettingsMenu.as('menu'),
  ],
}
