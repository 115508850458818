import { type TPlayer } from 'video.js'
import { VideojsErrorsCodes } from '|>/shared/constants/errors'
import { Events } from '|>/shared/events'
import { bind_ } from '|>/shared/fn'
import { div, insertContent, span } from '|>/shared/h'
import { ErrorsCodes, type TExternalError } from '|>/shared/types/errors'
import { on, register } from '|>/shared/vjs'
import { BaseModalDialog } from '../base'
import { FALLBACK_ERROR_CODE, MediaErrorCode } from './lib'

import './error-display.css'

const videoJsErrorsCodesValues = Object.values(ErrorsCodes)

@register
export class ErrorDisplay extends BaseModalDialog {
  title = 'Error'
  codeTitle = 'Error Code'

  savedNativeError?: MediaError
  externalError?: Events.Media.Error
  errorMap: NonNullable<Events.Controls.SetErrorMap> = {}

  declare titleEl?: HTMLElement
  declare descriptionEl?: HTMLElement
  declare codeTitleEl?: HTMLElement
  declare codeEl?: HTMLElement

  constructor(player: TPlayer, options) {
    super(player, options)
    this.descEl_.remove()

    // default hotkeys are predefined
    // '0': MediaErrorCode.MEDIA_ERR_CUSTOM
    // '1': MediaErrorCode.MEDIA_ERR_ABORTED
    // '2': MediaErrorCode.MEDIA_ERR_NETWORK
    // '3': MediaErrorCode.MEDIA_ERR_DECODE
    // '4': MediaErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED
    // '5': MediaErrorCode.MEDIA_ERR_ENCRYPTED
    // '-2': MediaErrorCode.PLAYER_ERR_TIMEOUT
    // rest - external are generated

    // prettier-ignore
    this.player_.trigger(Events.Controls.RegisterHotkey, [
      [['e', '0'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_CUSTOM)],
      [['e!', '0!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_CUSTOM)],
      [['e', '1'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_ABORTED)],
      [['e!', '1!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_ABORTED)],
      [['e', '2'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_NETWORK)],
      [['e!', '2!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_NETWORK)],
      [['e', '3'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_DECODE)],
      [['e!', '3!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_DECODE)],
      [['e', '4'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED)],
      [['e!', '4!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED)],
      [['e', '5'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_ENCRYPTED)],
      [['e!', '5!'], () => this.triggerError(MediaErrorCode.MEDIA_ERR_ENCRYPTED)],
      [['e', '-', '2'], () => this.triggerError(MediaErrorCode.PLAYER_ERR_TIMEOUT)],
      [['e!', '-!', '2!'], () => this.triggerError(MediaErrorCode.PLAYER_ERR_TIMEOUT)],
    ])

    this.player_.on(
      Events.Controls.SetErrorMap,
      (_: any, map: Events.Controls.SetErrorMap | undefined) => {
        if (map) {
          Object.assign(this.errorMap, map)
          this.player_.trigger(
            Events.Controls.RegisterHotkey,
            this.generateHotkeysFromErrorMap(map)
          )
          this.fillError()
        }
      }
    )

    // set external error from the app,
    // it is used to set business logic error
    this.player_.on(
      Events.Media.Error,
      (_: any, error: Events.Media.Error | undefined) => {
        // native videoJs error
        const isVideoJsErrorCode =
          typeof error === 'number' && error in videoJsErrorsCodesValues
        if (isVideoJsErrorCode) return this.player_.error(error) // this also goes through stopVideoAndShowError

        // some custom error from the app
        this.externalError = error
        if (this.error()) return this.stopVideoAndShowError()

        // empty error in error event considered as 'remove error'
        this.hideError()
      }
    )

    // open error modal on error
    this.player_.on('error', bind_(this, this.stopVideoAndShowError))
    this.player_.tech_.on('error', bind_(this, this.stopVideoAndShowError))
  }

  hideError() {
    this.savedNativeError = undefined

    this.close()
  }

  generateHotkeysFromErrorMap(errMap: Events.Controls.SetErrorMap) {
    const hotkeys: any[] = []

    for (const errorCode in errMap) {
      // errorCode - WG0051 / 4 / 403 - wbs / status / videoJs code
      const value = errMap[errorCode]
      if (!value) continue

      if (errorCode in Object.values(VideojsErrorsCodes)) continue // VideoJsErrorsCodes hotkeys already defined in constructor

      const sequence = errorCode.toLowerCase().split('')
      const sequenceForModal = sequence.map((char) => `${char}!`)

      const triggerFunc = () => this.triggerError(errorCode)

      hotkeys.push([['e', ...sequence], triggerFunc])
      hotkeys.push([['e!', ...sequenceForModal], triggerFunc])
    }

    return hotkeys
  }

  stopVideoAndShowError() {
    this.savedNativeError = this.player_.error()
    this.player_.pause()
    this.player_.reset() // sometimes native error may not stop video, this is workaround

    this.addErrorClass()

    this.open()
  }

  addErrorClass() {
    this.player_.addClass('vjs-error')
  }
  removeErrorClass() {
    this.player_.removeClass('vjs-error')
  }

  @on(Events.Media.Load)
  trackReadyEvent() {
    this.savedNativeError = undefined
    if (!this.externalError) this.removeErrorClass()
  }

  triggerError(err: Events.Media.Error) {
    // trigger error if given
    if (err != null) {
      this.player_.trigger(Events.Media.Error, err)
      return
    }
  }

  /*
   * Get error object
   */
  error() {
    // internal player error or external error from the app
    const error = this.savedNativeError || this.externalError

    // error code
    const code =
      typeof error === 'number' || typeof error === 'string'
        ? error
        : error?.code

    // if error has been defined in the error map, use it instead
    let mappedError =
      this.errorMap != null && code != null ? this.errorMap[code] : undefined

    // if error code is a number or a string -> this is shorthand for mapped error code
    if (typeof mappedError === 'number' || typeof mappedError === 'string') {
      mappedError = { code: mappedError }
    }

    // `false` means that error explicitly defined as not error
    if (mappedError === false) return

    let revisedMappedError: undefined | MediaError | Partial<TExternalError>
    const err = mappedError || error
    if (err != null) {
      if (typeof err === 'object') {
        if (err.code == null) (err as any).code = code
        revisedMappedError = err
      } else {
        revisedMappedError = { code: error as number | string }
      }
    }

    if (revisedMappedError != null && !revisedMappedError?.code) {
      // case if code is missing "{ code: undefined }"
      // show fallback error

      // TODO it is even better to fire native error here FALLBACK_ERROR_CODE
      this.externalError = (this.errorMap?.[
        FALLBACK_ERROR_CODE
      ] as TExternalError) ?? { code: FALLBACK_ERROR_CODE }
      return this.externalError
    }

    return revisedMappedError
  }

  override content() {
    const elements = [
      (this.titleEl = div('.error-title', this.localize(this.title))),
      (this.descriptionEl = div('.error-description')),
      div(
        '.error-code',
        (this.codeTitleEl = span('.error-code-title')),
        (this.codeEl = span('.error-code-name'))
      ),
    ]
    this.fillError()
    return elements
  }

  fillError() {
    const error = this.error()

    if (!error) return

    let title = 'title' in error ? error.title || this.title : this.title
    let message = error.message || ''
    let code = error.code

    // localize using values
    title = this.localize(title) // "Error" string by default
    message = this.localize(message)
    let codeTitle = this.localize(this.codeTitle) // "Error Code:" string

    // localize using error code
    // keys can be like `NOT_FOUND_title` and `NOT_FOUND_message` or just `NOT_FOUND`
    // if localization strings exists, they have higher priority, than strings from error map
    const message2FromCodeKey = `${code}` // NOT_FOUND
    message = this.localize(message2FromCodeKey, undefined, message)

    const titleFromCodeKey = `${code}_title` // NOT_FOUND_title
    const messageFromCodeKey = `${code}_message` // NOT_FOUND_message
    title = this.localize(titleFromCodeKey, undefined, title)
    message = this.localize(messageFromCodeKey, undefined, message)

    // fill elements with localized strings
    if (this.titleEl) insertContent(this.titleEl, `${title}`)
    if (this.descriptionEl) insertContent(this.descriptionEl, `${message}`)
    if (this.codeTitleEl) insertContent(this.codeTitleEl, `${codeTitle}`)
    if (this.codeEl) insertContent(this.codeEl, `${code}`)
  }

  override handleLanguagechange() {
    this.fillError()
  }
}

// Default values
ErrorDisplay.options = {
  className: 'vjs-x-error-display',
  pauseOnOpen: true,
  fillAlways: true,
  temporary: false,
  uncloseable: true,
}
