import { memo, useEffect } from 'react'
import type { ExtOComponent } from 'video.js'
import type { Configuration } from '|>/core'
import { Events } from '|>/shared/events'
import { asDepsArray } from '|>/shared/helpers'
import type { TExternalError } from '|>/shared/types/errors'
import { VideojsBox, type VideojsBoxRef } from './VideojsBox'
import { useConfiguration } from './hooks/useConfiguration'
import { useEventListener } from './hooks/useEventListener'
import { useEventTrigger } from './hooks/useEventTrigger'
import { useOptions } from './hooks/useOptions'
import { usePlayer } from './hooks/usePlayer'
import { useSource } from './hooks/useSource'

import 'video.js/dist/video-js.css'
import '../css/videojs.css'

export type VideojsPlayerProps = {
  using?: ExtOComponent
  sourceUrl?: string
  title?: string
  subtitle?: string
  drm?: any
  onPlayNext?: () => void
  onPlayPrevious?: () => void
  onClose?: (_?: undefined, event?: any) => void
  liveProgressBar?: Events.Media.ProgressBarChanged
  onLiveRewindTo?: (timestamp: number | undefined, event?: any) => void
  onGoLive?: (_?: undefined, event?: any) => void
  onProgressReachedEnd?: () => void
  isRewound?: boolean
  isRewindPending?: boolean
  rewoundTargetStartSec?: number
  rewoundUrlStartSec?: number
  isAutoLiveTracker?: boolean
  errorMap?: Events.Controls.SetErrorMap
  externalError?: TExternalError | number | string | null | undefined
  chromecast?: Configuration['chromecast']
  cmcd?: Configuration['cmcd']
  onReady?: (box: VideojsBoxRef) => void
  isUrlRequestPending?: boolean
  locale?: string
  muted?: boolean
  isRenderedChildren?: boolean
}

/**
 * Contrainer component for VideoJS player (wrapper)
 * Handles logic, events and player initialization
 */
export const VideojsPlayer = memo(_VideojsPlayer)
function _VideojsPlayer({
  using,
  sourceUrl,
  title,
  subtitle,
  drm,
  liveProgressBar,
  onPlayNext,
  onPlayPrevious,
  onClose,
  onLiveRewindTo,
  onGoLive,
  onProgressReachedEnd,
  isRewound = false,
  isRewindPending = false,
  rewoundTargetStartSec = 0,
  rewoundUrlStartSec = 0,
  isAutoLiveTracker = false,
  errorMap,
  chromecast,
  cmcd,
  onReady,
  isUrlRequestPending,
  externalError,
  locale,
  muted = false,
  isRenderedChildren = false,
}: VideojsPlayerProps): JSX.Element {
  const { player, ref, setPlayer } = usePlayer()
  const options = useOptions({ language: locale, muted })
  const configuration = useConfiguration({
    using,
    onReady: setPlayer,
    chromecast,
    cmcd,
  })
  const sources = useSource(sourceUrl, drm?.la)

  useEffect(() => {
    if (onReady && player) onReady(ref.current!) // reference should be available at this point
  }, [onReady, player])

  /*
   * add event listeners to underlying videojs player
   */

  useEventListener(player, Events.Player.Close, onClose)
  useEventListener(player, Events.Player.LiveRewind, onLiveRewindTo)
  useEventListener(player, Events.Player.GoLive, onGoLive)
  useEventListener(player, Events.Player.PlayNext, onPlayNext)
  useEventListener(player, Events.Player.PlayPrevious, onPlayPrevious)
  useEventListener(
    player,
    Events.Player.ProgressReachedEnd,
    onProgressReachedEnd
  )

  // prettier-ignore
  useEventListener(player, 'waiting' as any, () => console.info('player is waiting'))
  // prettier-ignore
  useEventListener(player, 'dispose' as any, () => console.info('player is disposed'))

  /*
   * trigger videojs player events on react component properties change
   */

  // update playing source
  useEventTrigger(player, Events.Media.Load, sources, [sources])

  // update title and subtitle
  useEventTrigger(player, Events.Media.TitleChanged, { title, subtitle }, [
    title,
    subtitle,
  ])

  // indicates player renders any app component
  useEventTrigger(player, Events.Media.IsRenderedChildren, isRenderedChildren, [
    isRenderedChildren,
  ])

  // update state of live ui
  useEventTrigger(player, Events.Media.IsRewound, isRewound, [isRewound])

  // update rewind request state - user clicked stamp
  useEventTrigger(
    player,
    Events.Media.RewoundTargetSec,
    rewoundTargetStartSec,
    [rewoundTargetStartSec]
  )

  // update rewind request state - url started from stamp
  useEventTrigger(player, Events.Media.RewoundUrlSec, rewoundUrlStartSec, [
    rewoundUrlStartSec,
  ])

  // use native live seeking - ignore: IsRewound, isAutoLiveTracker, onGoLive (if app is confident in 'rewind')
  useEventTrigger(player, Events.Media.IsAutoLiveTracker, isAutoLiveTracker, [
    isAutoLiveTracker,
  ])

  // translated / custom texts for errors
  useEventTrigger(player, Events.Controls.SetErrorMap, errorMap, [errorMap])

  // custom adMatcher
  // useEventTrigger(player, Events.Media.AdMatcher, adMatcher, [adMatcher])

  // show play next control button if handler is provided, hide otherwise
  useEventTrigger(
    player,
    Events.Controls.ShowPlayNextControl,
    Boolean(onPlayNext),
    [onPlayNext]
  )

  // show play previous control button if handler is provided, hide otherwise
  useEventTrigger(
    player,
    Events.Controls.ShowPlayPreviousControl,
    Boolean(onPlayPrevious),
    [onPlayPrevious]
  )

  useEventTrigger(player, Events.Media.IsUrlPending, isUrlRequestPending, [
    isUrlRequestPending,
  ])

  useEventTrigger(player, Events.Media.IsRewindPending, isRewindPending, [
    isRewindPending,
  ])

  useEventTrigger(player, Events.Media.Error, externalError, [externalError])

  // update live progress bar boundaries
  useEventTrigger(
    player,
    Events.Media.ProgressBarChanged,
    liveProgressBar,
    asDepsArray(liveProgressBar, 5)
  )

  /*
   * render videojs player itself (inside wrapper)
   */

  return (
    <VideojsBox ref={ref} options={options} configuration={configuration} />
  )
}
