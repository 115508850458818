import { div, ul } from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'
import { SettingsMenuAudioMenu } from './settings-menu-audio-menu'
import { SettingsMenuSubsMenu } from './settings-menu-subs-menu'

/**
 * Settings menu with audio and subtitles options
 */
@register
export class SettingsMenu extends BaseComponent {
  declare contentEl_: HTMLElement
  declare audio: SettingsMenuAudioMenu
  declare subs: SettingsMenuSubsMenu

  override createEl() {
    return div(
      '.vjs-x-settings-menu.vjs-menu',
      (this.contentEl_ = ul('.vjs-menu-content', { role: 'menu' }))
    )
  }

  update() {
    this.audio.update()
    this.subs.update()
  }
}

SettingsMenu.options = {
  children: [
    // audio menu item
    SettingsMenuAudioMenu.as('audio'),

    // subtitles menu item
    SettingsMenuSubsMenu.as('subs'),
  ],
}
