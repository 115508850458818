import videojs from 'video.js'

// add default translations for some languages
import './en'
import './es'
import './fr'
import './hi'
import './th'
import './vi'

export const addLanguage = videojs.addLanguage
