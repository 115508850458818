import videojs from 'video.js'
import es from 'video.js/dist/lang/es.json'

videojs.addLanguage('es', es)
videojs.addLanguage('es', {
  'Error': 'Error',
  'Error Code': 'Código de error',

  // add custom spanish translations here
})
