import videojs from 'video.js'
import { addClass, div, insertContent, removeClass } from '|>/shared/h'
import { register } from '|>/shared/vjs'
import { BaseComponent } from '../base'

import './tap-handler.css'

// time means how long indicators showed
const SEQUENCE_MS = 500

// each seek step adds such amount
const SEEK_SEC = 5

// delay between single and double tap
const DOUBLE_TAP_DELAY = 300

// direction of seek
const enum Direction {
  Left,
  Right,
}

/**
 * TapHandler component
 */
@register
export class TapHandler extends BaseComponent {
  // elements that show indicators
  declare leftLabelElement: Element | undefined
  declare rightLabelElement: Element | undefined

  // current amount of sec to seek
  sec: number | undefined

  // current direction
  direction: Direction | undefined

  // timeout ids for labels and single tap
  private labelsTimeoutId: number | undefined
  private tapTimeoutId: number | undefined

  constructor(player, options) {
    super(player, options)
    player.el_.addEventListener('touchend', this.handleTouchEnd.bind(this))
  }

  showLabel(direction: Direction, seconds: number) {
    if (direction === Direction.Left && this.leftLabelElement) {
      insertContent(this.leftLabelElement, `-${seconds}sec`)
      addClass(this.leftLabelElement, 'show-animation')
    }
    if (direction === Direction.Right && this.rightLabelElement) {
      insertContent(this.rightLabelElement, `+${seconds}sec`)
      addClass(this.rightLabelElement, 'show-animation')
    }
  }

  hideLabels() {
    if (this.leftLabelElement) {
      removeClass(this.leftLabelElement, 'show-animation')
    }
    if (this.rightLabelElement) {
      removeClass(this.rightLabelElement, 'show-animation')
    }
  }

  override createEl() {
    const el = div('.vjs-tap')

    // render left and right indicators only on touch devices
    if (videojs.browser.TOUCH_ENABLED) {
      el.appendChild(
        div(
          '.vjs-left-tap-area',
          null,
          (this.leftLabelElement = div('.vjs-left-tap-label'))
        )
      )
      el.appendChild(
        div(
          '.vjs-right-tap-area',
          null,
          (this.rightLabelElement = div('.vjs-right-tap-label'))
        )
      )
    }

    return el
  }

  seekAction(direction: Direction) {
    if (this.player_.error()) return

    // seek immediately
    const curTime = this.player_.currentTime()
    const deltaTime = (direction === Direction.Right ? 1 : -1) * SEEK_SEC
    const newTime = curTime + deltaTime
    this.player_.currentTime(newTime)

    // save seek sequence seconds to show them
    this.sec = (this.sec || 0) + SEEK_SEC

    // show indicators
    this.showLabel(direction, this.sec)

    // restart stop of a seek sequence
    this.clearSeekSequence()
  }

  clearSeekSequence() {
    window.clearTimeout(this.labelsTimeoutId)
    this.labelsTimeoutId = window.setTimeout(() => {
      this.direction = undefined
      this.sec = undefined
      this.hideLabels()
    }, SEQUENCE_MS)
  }

  isTargetable(target: HTMLElement) {
    return (
      target &&
      (target.tagName === 'VIDEO' || // tap directly on video
        target.matches('.vjs-tap') || // tap directly on tap handler
        target.closest('.vjs-tap') != null) // tap on child of tap handler
    )
  }

  handleTouchEnd(event) {
    // ignore taps on non-targetable elements,
    // like player controls and buttons
    if (!this.isTargetable(event.target)) return

    if (this.tapTimeoutId) {
      this.tapTimeoutId = void window.clearTimeout(this.tapTimeoutId)
      this.labelsTimeoutId = void window.clearTimeout(this.labelsTimeoutId)

      const touchX = event.changedTouches[0].clientX
      const playerEl = this.player().el() as HTMLElement
      const playerWidth = playerEl.offsetWidth

      this.handleDoubleTap(
        touchX < playerWidth / 2 // to the left or right of the player
          ? Direction.Left
          : Direction.Right
      )
    } else {
      this.tapTimeoutId = window.setTimeout(() => {
        this.handleSingleTap()
        this.tapTimeoutId = undefined
      }, DOUBLE_TAP_DELAY)
    }
  }

  handleSingleTap() {
    if (this.player().paused()) {
      this.player().play()
    } else {
      this.player().pause()
    }
  }

  handleDoubleTap(direction: Direction) {
    this.seekAction(direction)
  }

  override dispose() {
    window.clearTimeout(this.tapTimeoutId)
    window.clearTimeout(this.labelsTimeoutId)
    super.dispose()
  }
}

export default TapHandler
