import videojs, { type TOptions, type TPlayer } from 'video.js'
import { defaultPlayer } from '|>/components'
import { isPlayerDebugMode } from '|>/shared/constants/debug'
import { flow } from '|>/shared/fn'
import * as chromecast from './addons/chromecast'
import * as cmcd from './addons/cmcd'
import * as eme from './addons/eme'
import * as instance from './addons/instance'
import type { Configuration } from './index.h'

/**
 * Default and mandatory options for all player instances
 */
export const defaults: TOptions = {
  autoplay: 'any', // maybe get it from option? (defined second time)
  controls: true,
  language: 'en',
  preload: 'auto',
  preloadTextTracks: true,
  playsinline: videojs.browser.IS_IOS, // only iOS or always true? https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#playsinline
  techOrder: ['html5'],
  muted: videojs.browser.IS_IOS,
  debug: isPlayerDebugMode,
  html5: {
    vhs: {
      handlePartialData: true,
      // enableLowInitialPlaylist: true,
      parse708captions: true,
      handleManifestRedirects: true,
      cacheEncryptionKeys: true,
      debug: isPlayerDebugMode,

      // send cookies with cross-origin requests
      // only of `with-credentials` param is set
      // withCredentials: isWithCredentials, // TODO:
    },
  },
}

export function createPlayer(
  el: Element,
  options: TOptions,
  {
    using = defaultPlayer,
    onReady,
    chromecast: chromecastConfiguration,
    cmcd: cmcdConfiguration,
  }: Configuration
): TPlayer | Promise<TPlayer> {
  // addons configuration step
  // this is where addon can adjust player's options
  // could return config synchronously or as a promise (if async initialization is needed)

  const config = flow(
    { ...defaults, ...options },
    eme.configure,
    cmcd.configure(cmcdConfiguration),
    chromecast.configure(chromecastConfiguration),
    instance.configure(using)
  )

  // player creation step
  // this step is separated to a function to allow both sync and async initialization
  // this is like an "await" tear point, but without actually use async/await syntax
  // because if function is marked as async - it will always return a promise,
  // effectively making it impossible to return a player instance synchronously
  function creator(config: TOptions): TPlayer {
    instance.replace(using)
    const player = videojs(el, config, () => {
      onReady?.(player)
    }) as unknown as TPlayer

    // addons injection step
    // this is where addon can adjust player's instance
    return flow(
      player,
      eme.inject,
      cmcd.inject, // injects cmcd only if it enabled
      chromecast.inject // injects chromecast only if it enabled
    )
  }

  // return player instance or promise of player instance
  return config instanceof Promise ? config.then(creator) : creator(config)
}
