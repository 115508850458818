import videojs from 'video.js'
import hi from 'video.js/dist/lang/hi.json'

videojs.addLanguage('hi', hi)
videojs.addLanguage('hi', {
  'Error': 'विषय',
  'Error Code': 'विषय कोड',

  // add custom hindi translations here
})
