import { register } from '|>/shared/vjs'
import { BaseTimeTooltip } from '../base'

/**
 * Display a time above the progress bar
 */
@register
export class LiveTimeTooltip extends BaseTimeTooltip {
  override updateTime(
    rect: { left: number; top: number; width: number; height: number },
    point: number,
    time: number,
    cb?: Function
  ) {
    this.requestNamedAnimationFrame('LiveTimeTooltip#updateTime', () => {
      // TODO: this is just as an example and for testing, will be changed after design is ready
      const content = absoluteTime(time) + '\n' + relativeTime(time)
      ;(this as any).update(rect, point, content)
      if (cb) cb()
    })
  }
}

/**
 * Get absolute time in format "12:34:56"
 */
function absoluteTime(time: number) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as const
  const format = new Intl.DateTimeFormat('en-US', options)
  return format.format(new Date(time))
}

/**
 * Get relative time in format "-1h 30m" or "+1h 30m"
 */
function relativeTime(time: number) {
  const now = Date.now()
  const diff = time - now
  const sign = diff < 0 ? '-' : '+'
  const abs = Math.abs(diff)

  const hours = Math.floor(abs / (60 * 60 * 1000))
  const minutes = Math.floor((abs % (60 * 60 * 1000)) / (60 * 1000))

  return `${sign}${hours}h ${minutes}m`
}
