import connectChromecast from '@silvermine/videojs-chromecast'
import videojs, { type TOptions, type TPlayer } from 'video.js'
import { addScriptAsync } from '|>/shared/dom'

//
// plugin documentation -> https://github.com/silvermine/videojs-chromecast
//

/**
 * Chromecast configuration
 */
export type ChromecastConfiguration = {
  enabled?: boolean
}

/**
 * Key to store chromecast configuration in player options
 */
const chromecastKey = '~~chromecast'

/**
 * Chromecast plugin configuration options
 */
export type ChromecastPluginOptions = {
  // id of a custom chromecast receiver app to use
  // defaults to the default media receiver id
  receiverAppID?: string

  // flag that tells the plugin whether or not it should automatically add
  // the chromecast button to the video.js player's control bar component
  // defaults to true
  addButtonToControlBar?: boolean

  // a zero-based number specifying the index of the chromecast button among
  // the control bar's child components (if `addButtonToControlBar` is set to true).
  // by default the chromecast button is added as the last child of the control bar.
  // a value less than 0 puts the button at the specified position from the end of the control bar.
  // note that it's likely not all child components of the control bar are visible.
  buttonPositionIndex?: number

  // by default, the chromecast button component will display only an icon.
  // setting `addCastLabelToButton` to true will display a label titled "Cast" alongside the default icon.
  addCastLabelToButton?: boolean
}

/**
 * Chromecast tech configuration options
 */
export type ChromecastTechOptions = {
  // function that this plugin calls when it needs a string that will be the title shown in the UI
  // that is shown when a Chromecast session is active and connected. When the this plugin calls
  // the `requestTitleFn`, it passes it the current source object and expects a string in return.
  // If nothing is returned or if this option is not defined, no title will be shown.
  requestTitleFn?: (source: any) => string

  // function that this plugin calls when it needs a string that will be the sub-title shown in the UI
  // that is shown when a Chromecast session is active and connected. When the this plugin calls
  // the `requestSubtitleFn`, it passes it the current source object and expects a string in return.
  // If nothing is returned or if this option is not defined, no sub-title will be shown.
  requestSubtitleFn?: (source: any) => string

  // function that this plugin calls when it needs an object that contains custom information necessary
  // for a Chromecast receiver app when a session is active and connected. When the this plugin calls
  // the `requestCustomDataFn`, it passes it the current source object and expects an object in return.
  // If nothing is returned or if this option is not defined, no custom data will be sent.
  // This option is intended to be used with a custom receiver application to extend its default capabilities.
  requestCustomDataFn?: (source: any) => any

  // function that this plugin calls before doing the request to load media.
  // The function gets called with the LoadRequest object as argument and expects it in return.
  modifyLoadRequestFn?: <T = any>(loadRequest: T) => T
}

/**
 * Chromecast framework, it is required to be included in the page
 * > Note that regardless of whether you are using this plugin via the pre-built JS or as a module,
 * > the Chromecast framework will need to be included after the plugin.
 */
const CHROMECAST_FRAMEWORK_SCRIPT_URL =
  'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'

/**
 * Flag to check if chromecast addon was initialized
 * This is a global flag intentionally, not per player, because it shows
 * if the chromecast framework was loaded, and it should be loaded only once
 */
let initialized: boolean | Promise<boolean> = false

/**
 * Initialize chromecast plugin
 * - add chromecast plugin to VideoJS
 * - preload Web Components polyfill
 * - inject chromecast framework
 */
function init(): void | boolean | Promise<boolean> {
  if (initialized) return initialized
  initialized = true

  // add chromecast plugin to VideoJS
  // do not add it if it is already there
  const chromecastPlugin = videojs.getPlugin('chromecast')
  if (chromecastPlugin == null) {
    connectChromecast(videojs, { preloadWebComponents: true })
  }

  // inject chromecast framework
  if (window.cast == null) {
    return (initialized = addScriptAsync(CHROMECAST_FRAMEWORK_SCRIPT_URL)
      .then(() => console.info('Chromecast framework loaded'))
      .catch((err) => console.error('Chromecast framework load fail', err))
      .then(() => (initialized = true)))
  }
}

/**
 * Configure chromecast plugin before player initialization
 */
export function configure(configuration?: ChromecastConfiguration) {
  const configurator = (options: TOptions): TOptions => {
    // store configuration in player options
    options[chromecastKey] = configuration || {}

    // do nothing if chromecast is disabled
    if (!options[chromecastKey].enabled) return options

    // add chromecast to techOrder
    // > Important: In addition to defining plugin configuration, you are required to define
    // > the player's techOrder option, setting 'chromecast' as the first Tech in the list.
    const techOrder: string[] = options.techOrder?.slice() ?? ['html5']
    // techOrder.unshift('chromecast')

    // add chromecast tech options
    const tech: ChromecastTechOptions = {
      // ?do we need those?
    }

    // return new options with chromecast in techOrder
    return { ...options, techOrder, chromecast: tech }
  }

  // initialize chromecast plugin
  // this is done only once, on the first player creation
  let initializator: void | boolean | Promise<boolean> = undefined
  if (configuration?.enabled) initializator = init()

  return initializator instanceof Promise
    ? initializator.then(() => configurator)
    : configurator
}

/**
 * Injects chromecast plugin and functionality into the player
 */
export function inject(player: TPlayer): TPlayer {
  // do nothing if chromecast is disabled
  const options = player.options_
  if (!options[chromecastKey]?.enabled) return player

  // initializes the chromecast plugin on the player
  player.chromecast({
    // do not add chromecast button to control bar, because we add it to top bunk manually
    addButtonToControlBar: false,
  })

  // workaround for https://github.com/silvermine/videojs-chromecast/issues/96
  player.on('dispose', () => {
    const castSession = player.chromecastSessionManager
      ?.getCastContext()
      ?.getCurrentSession()
    if (castSession) castSession.endSession(true)
  })

  return player
}
