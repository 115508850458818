import { register } from '|>/shared/vjs'
import { BasePlayToggle } from '../base'

import './play-central-toggle.css'

/**
 * Central play toggle button
 * https://github.com/videojs/video.js/blob/e78bcc7b2d829fce01451cf105b918d8feec4548/src/js/control-bar/play-toggle.js
 */
@register
export class PlayCentralToggle extends BasePlayToggle {}

PlayCentralToggle.options = {
  className: 'vjs-x-play-central-toggle',
}
