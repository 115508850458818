import { register } from '|>/shared/vjs'
import { BaseVolumeControl } from '../base'
import { VolumeBar } from './volume-bar'

@register
export class VolumeControl extends BaseVolumeControl {
  declare volumeBar: VolumeBar

  makeActive() {
    // https://github.com/videojs/video.js/blob/964c40e18618d034e7bd3ba65a3514a9efc6df3b/src/js/control-bar/volume-control/volume-control.js#L55
    this.volumeBar.addClass('vjs-slider-active')
    this.addClass('vjs-slider-active')
    this.trigger('slideractive')
  }

  makeInactive() {
    this.volumeBar.removeClass('vjs-slider-active')
    this.removeClass('vjs-slider-active')
    this.trigger('sliderinactive')
  }
}

VolumeControl.options = {
  className: 'vjs-x-volume-control',
  children: [VolumeBar.as('volumeBar')],
}
